import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/link-resolver"
import Newsletter from "../components/homepage/newsletter"

function NewsletterPage() {
  return (
    <Layout>
      <SEO
        keywords={["Signup for Lantern`&gt;`s Newsletter"]}
        title="Signup for Lantern`&gt;`s Newsletter"
        description="Signup for Lantern`&gt;`s Newsletter"
        excludeSiteMetadataTitle
      />

      <div className="w-full bg-grey-lighter md:py-24">
        <Newsletter location="Newsletter Page" border />
      </div>
    </Layout>
  )
}

export default withPrismicPreview(NewsletterPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
